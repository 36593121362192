<template>
   <section class="content-container">
      <router-view/>
   </section>
</template>

<script>
export default {
   data() {
      return {
         title: "Reconoce",
      };
   },
   methods: {
      goTo(route) {
         var current_route = this.$route.name;
         if (route == current_route) {
            return;
         } else {
            this.$router.push({ name: route });
         }
      },
      activeTab(tab) {
         var current_route = this.$route.name;
         if (tab == current_route) {
            return "active";
         }
      },
   },
};
</script>